import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"

import Layout, { Card } from "../../components/Layout"
import SEO from "../../components/SEO"
import BlogTeaser from "../../components/BlogTeaser"

const Photo = styled.div`
  flex-basis: 25%;
`

const Intro = styled.div`
  flex-basis: 75%;
  padding: 1rem;
`

const Blogs = styled.div`
  display: flex;
  margin-left: -1rem;
`

const BlogTeaserWithMargin = styled(BlogTeaser)`
  margin-left: 1rem;
  flex-basis: 33%;
`

export default ({
  data: {
    prismicFrontpage: { data },
  },
  pageContext: { locale },
}) => (
  <Layout locale={locale}>
    <SEO title="Home" />
    <Card css={{ display: "flex" }}>
      {data.face.localFile && (
        <Photo>
          <Img fluid={data.face.localFile.childImageSharp.fluid} />
        </Photo>
      )}
      <Intro
        dangerouslySetInnerHTML={{
          __html: data.personal.html,
        }}
      />
    </Card>
    {data.body.map(block => {
      if (block.__typename === "PrismicFrontpageBodyTextImage") {
        return (
          <Card padded key={block.id}>
            {block.items.map((item, index) => (
              <div
                key={block.id + "-" + index}
                dangerouslySetInnerHTML={{ __html: item.text_body.html }}
              />
            ))}
          </Card>
        )
      } else {
        return (
          <Card padded key={block.id}>
            <h2>Featured blogs</h2>
            <Blogs>
              {block.items.map(({ blogpost: { document } }) => (
                <BlogTeaserWithMargin
                  key={document[0].uid}
                  node={document[0]}
                  vertical
                />
              ))}
            </Blogs>
          </Card>
        )
      }
    })}
  </Layout>
)

export const query = graphql`
  query Frontpage($langcode: String!) {
    prismicFrontpage(lang: { eq: $langcode }) {
      data {
        title {
          text
        }
        personal {
          html
        }
        face {
          localFile {
            childImageSharp {
              fluid(maxWidth: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        body {
          ... on PrismicFrontpageBodyTextImage {
            id
            items {
              text_body {
                html
              }
            }
          }
          ... on PrismicFrontpageBodyBlogs {
            id
            items {
              blogpost {
                document {
                  ...BlogTeaser
                }
              }
            }
          }
        }
      }
    }
  }
`
